<template>
  <transition :appear="true" name="slide">
    <div class="answer-question" v-show="showGame">
      <div class="mask" v-show="isOver">
        <div class="block" :style="end_bg_st">
          <div class="title">本次答题</div>
          <div class="res">
            共答对<span style="color:#0B54B3;">{{ successNum }}</span>道题，此次用时<span style="color:#0B54B3;">{{ cost }}</span>
          </div>
          <router-link tag="div" to="/home/answer-home/answer-rank" class="rank">查看动态排名</router-link>
          <router-link tag="div" to="/home/answer-home" class="restart" @click="_startGames">
            重新开始（剩余{{ rank.seconds }}次）
          </router-link>
        </div>
        <router-link to="/" tag="div" class="close"></router-link>
      </div>
      <scroll
          ref="scroll"
          class="scroll"
          :data="data"
      >
        <div>
          <div class="title" :style="bl_st">
            <div class="user">
              <div class="avatar">
                <img :src="userAvatar" alt="">
              </div>
              <div class="info">
                <div class="top">
                  <div class="name">{{ userName }}</div>
                  <div class="remainder">
                    <div class="ico"></div>
                    <div class="text">剩余答题次数
                      <span class="warn">{{ rank.seconds }}</span>
                      次
                    </div>
                  </div>
                </div>
                <div class="bottom" :style="ac_co_st">
                  <div>最高答题记录：{{ rank.num }}题 用时：{{ rank.cost }}</div>
                </div>
              </div>
            </div>
            <div class="seconds">{{ seconds }}</div>
          </div>
          <div class="question">
            <div class="question-title">第{{ currentIndex + 1 }}道题</div>
            <div class="question-text">
              {{ question.question_content }}
            </div>
            <div class="answer">
              <template v-if="question.question_type === 1">
                <div
                    class="answer-item" v-for="(item,index) in question.question_options"
                    :class="{success: answerObj.success === index, error: answerObj.error === index, 'suser': (superguids.includes(userGuid) && item.guid === question.question_answer)}"
                    :key="index"
                    @click="handleSelected(item.guid,index)">
                  <div class="text">{{ item.val }}</div>
                  <div class="ico"></div>
                </div>
              </template>
              <template v-else-if="2">
                <div class="answer-title">
                  <div class="ico"></div>
                  填写答案<span v-if="superguids.includes(userGuid) && question.question_options">---{{ question.question_options[0].val }}</span>
                </div>
                <field class="answer-inp" v-model="answer.text" type="textarea" placeholder="请填写您的答案..."></field>
                <div class="answer-notice" v-if="answer.error">
                  <span style="color: rgba(201, 23, 23, 1)">*回答错误</span>
                  正确答案：{{ question.question_options[0].val }}
                </div>
                <div class="answer-btn" @click="handleSubmit" :style="font_color">提交答案</div>
              </template>
            </div>
          </div>
        </div>
      </scroll>
      <router-view></router-view>
    </div>
  </transition>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { mapGetters } from 'vuex'
import { gameOver, startGames } from '@/api/Questions'
import { Field } from 'vant'

// const baseSeconds = 60
const activity = localStorage.getItem('activity') ? JSON.parse(localStorage.getItem('activity')) : {}
export default {
  name: 'AnswerQuestion',
  components: {
    Scroll,
    Field
  },
  computed: {
    question () {
      return this.data[this.currentIndex] ? this.data[this.currentIndex] : {}
    },
    ...mapGetters(['userAvatar', 'userName', 'userGuid', 'agencyGuid'])
  },
  data () {
    return {
      data: [],
      rank: {
        seconds: 0,
        num: '-',
        cost: '-'
      },
      currentIndex: -1,
      answerObj: {
        success: -1,
        error: -1,
        flag: false
      },
      answer: {
        text: '',
        error: false,
        flag: false
      },
      seconds: 60,
      isOver: false,
      isShare: false,
      showGame: false,
      cost: '',
      successNum: 0,
      logGuid: '',
      bl_st: '',
      font_color: '',
      end_bg_st: '',
      ac_co_st: '',
      ques_num: 0,
      superguids: [],
      use_time: 0
    }
  },
  created () {
    if (!activity || !activity.activitySetting) {
      this.$toast.fail('暂时没有活动')
      let _this = this
      _this.back()
      return false
    }
    this.seconds = activity.activitySetting.answer_time
    this.bl_st = 'background-image:url(' + activity.activitySetting.activity_answer_bg_img + ')'
    this.end_bg_st = 'background-image:url(' + activity.activitySetting.activity_end_bg_img + ')'
    this.superguids = activity.activitySetting.susers.split(',')
    console.log('superguids', this.superguids)
  },
  mounted () {
    this._startGames()
    // this.shareInit()
  },
  beforeDestroy () {
    clearTimeout(this.timer)
    clearInterval(this.countDownTime)
  },
  methods: {
    back () {
      let index = this.$route.path.indexOf('/answer-question')
      let path = this.$route.path.substring(0, index)
      this.$router.push(path)
    },
    _startGames () {
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      startGames({
        c_user_guid: this.userGuid,
        agency_guid: this.agencyGuid,
        activity_guid: activity.activity_guid,
        version: 'v3'
      }).then(res => {
        const {
          code,
          data,
          msg
        } = res
        if (code === 200) {
          this.data = data.questions
          this.rank = data.rank
          this.currentIndex = 0
          this.showGame = true
          this.logGuid = data.log_guid
          this.$toast.clear()
        } else {
          this.back()
          this.$toast.fail(msg)
        }
      })
    },
    handleSelected (guid, index) {
      if (this.answerObj.flag) return
      if (guid === this.question.question_answer) {
        this.answerObj = {
          success: index,
          error: -1,
          flag: true
        }
        this.next()
      } else {
        this.question.question_options.forEach((item, k) => {
          if (item.guid === this.question.question_answer) {
            this.answerObj = {
              success: k,
              error: index,
              flag: true
            }
          }
        })
        this._gameOver()
      }
    },
    handleSubmit () {
      if (this.answer.flag) return
      const answerStr = this.answer.text.replace(/\s*/g, '')
      if (answerStr === '') {
        return this.$toast.fail('请填写答案')
      }
      if (answerStr === this.question.question_options[0].val) {
        this.answer.flag = true
        this.next()
      } else {
        this.answer.error = true
        this.answer.flag = true
        this._gameOver()
      }
    },
    next () {
      clearTimeout(this.timer)
      clearInterval(this.countDownTime)
      this.successNum += 1
      if (this.currentIndex >= this.data.length - 1) {
        this.isShare = true
        this._gameOver(true)
      } else {
        this.timer = setTimeout(() => {
          this.currentIndex += 1
          this.answerObj = {
            success: -1,
            error: -1,
            flag: false
          }
          this.answer = {
            text: '',
            error: false,
            flag: false
          }
        }, 500)
      }
    },
    _gameOver () {
      clearTimeout(this.timer)
      clearInterval(this.countDownTime)
      this.timer = setTimeout(() => {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        gameOver({
          c_user_guid: this.userGuid,
          correct_num: this.successNum,
          question_log_guid: this.logGuid,
          agency_guid: this.agencyGuid,
          use_time: this.use_time,
          activity_guid: activity.activity_guid
        }).then(res => {
          const {
            code,
            data,
            msg
          } = res
          if (code === 200) {
            this.isOver = true
            this.cost = data.cost
            this.$toast.clear()
          } else {
            this.$toast.fail(msg)
          }
        })
      }, 2000)
    },
    countDown () {
      clearInterval(this.countDownTime)
      // this.seconds = baseSeconds
      this.countDownTime = setInterval(() => {
        this.seconds--
        this.use_time++
        if (this.seconds <= 0) {
          this._gameOver()
          clearInterval(this.countDownTime)
        }
      }, 1000)
    }
  },
  watch: {
    currentIndex (index) {
      this.countDown()
      if (index >= this.data.length - 1) {
        this.isShare = true
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.answer-question
  position absolute
  z-index 100
  top 0
  left 0
  right 0
  bottom 0
  background rgba(249, 249, 249, 1)

  .warn
    color:#FFEA00

  .warning
    color rgba(254, 132, 93, 1)

  .mask
    position absolute
    z-index 101
    top 0
    left 0
    right 0
    bottom 0
    background rgba(0, 0, 0, .7)

    .block
      display table
      width 604px
      height 691px
      margin 200px auto 0 auto
      bg-image2('msyq-question/over-block20230412')
      background-size 100% 100%
      background-repeat no-repeat

      .title
        display flex
        justify-content center
        width 100%
        margin-top 252px
        font-size 36px
        color rgba(52, 48, 42, 1)

      .res
        display flex
        justify-content center
        width 100%
        margin-top 28px
        font-size 30px
        color rgba(52, 48, 42, 1)

      .rank
        display flex
        align-items center
        justify-content center
        width 424px
        height 72px
        margin 114px auto 20px auto
        background: #FFFFFF;
        border-radius 34px
        font-size 30px
        color: #AF6911;

      .restart
        display flex
        align-items center
        justify-content center
        width 424px
        height 72px
        margin 0 auto
        background: #11B7AD;
        border-radius 34px
        font-size 30px
        color: #FFFFFF;
        text-shadow: 1px 4px 5px rgba(129,17,15,0.06);

    .close
      width 60px
      height 60px
      margin 46px auto
      bg-image2('msyq-question/close')
      background-size 100% 100%
      background-repeat no-repeat

  .scroll
    position absolute
    top 0
    left 0
    right 0
    bottom constant(safe-area-inset-bottom)
    bottom env(safe-area-inset-bottom)
    overflow hidden

    .title
      display table
      width 100%
      height 346px
      bg-image2('msyq-question/answer-title20230412')
      background-size 100% 100%
      background-repeat no-repeat

      .user
        display flex
        align-items center
        width 100%
        height 120px
        margin-top 64px

        .avatar
          width 120px
          height 120px
          margin-left 40px
          margin-right 20px
          padding 4px
          box-sizing border-box
          box-shadow: 0 5px 18px 0 rgba(21, 131, 103, .3)
          border-radius 60px
          background rgba(255, 255, 255, 1)
          overflow hidden

          img
            width 100%
            height 100%
            border-radius 50%

        .info
          flex 1
          height 100%

          .top
            display flex
            align-items center

            .name
              width 150px
              font-size 34px
              padding-top 8px
              color #ffffff;
              no-wrap()

            .remainder
              display flex

              .ico
                width 20px
                height 24px
                margin-top 16px
                margin-right 10px
                background-image:url('https://msyd-1304515158.cos.ap-beijing.myqcloud.com/uploads/231228/cb826b51-f541-8dc6-786a-78f4b9585cdc.png')
                background-size 100% 100%
                background-repeat no-repeat

              .text
                width 340px
                padding-top 16px
                font-size 26px
                color: #ffffff;
                no-wrap()

          .bottom
            display flex
            align-items center
            justify-content center
            width 500px
            height 50px
            margin-top 20px
            font-size 26px
            color rgba(255, 255, 255, 1)
            border-radius 26px
            position relative
            opacity: 1;
            div{
              color:#ffffff;
              opacity: 1;
              position relative
            }
          .bottom::before{
            content:'';
            position absolute
            width:100%;
            height 100%;
            background: #080B26;
            opacity: 0.64;
            border: 1px solid #504A5E;
            border-radius: 26px;
          }

      .seconds
        display flex
        align-items center
        justify-content center
        width 96px
        height 96px
        margin-top 70px
        margin-left auto
        margin-right 84px
        background rgba(255, 247, 243, 1)
        border-radius 50%
        border 2px solid rgba(208, 98, 21, 1)
        font-size 30px
        color rgba(208, 98, 21, 1)

    .question
      width 100%
      margin-top -16px

      .question-title
        display flex
        justify-content center
        width 100%
        font-size 34px
        color rgba(30, 30, 30, 1)

      .question-text
        width 100%
        line-height 44px
        padding 40px
        box-sizing border-box
        text-indent 56px
        font-size 28px
        color rgba(77, 77, 77, 1)

      .answer
        width 100%
        padding 0 40px
        box-sizing border-box

        .answer-item
          display flex
          align-items center
          width 100%
          margin-top 30px
          padding 20px 40px
          box-sizing border-box
          background rgba(236, 236, 236, 1)
          border-radius 20px
          color rgba(38, 38, 38, 1)

          &.success
            background rgba(213, 99, 20, 1)
            color rgba(255, 255, 255, 1)

            .ico
              width 34px
              height 24px
              bg-image2('msyq-question/success-ico')
              background-size 100% 100%
              background-repeat no-repeat
          &.suser
            background #6186E6
            color rgba(255, 255, 255, 1)
            .ico
              width 34px
              height 24px
              bg-image2('msyq-question/success-ico')
              background-size 100% 100%
              background-repeat no-repeat
          &.error
            background rgba(56, 52, 49, 1)
            color rgba(255, 255, 255, 1)

            .ico
              width 26px
              height 26px
              bg-image2('msyq-question/err-ico')
              background-size 100% 100%
              background-repeat no-repeat

          .text
            width 528px
            margin-right 20px
            font-size 28px
            line-height 44px

        .answer-title
          display flex
          align-items center
          width 100%
          font-size 30px
          color rgba(192, 103, 41, 1)

          .ico
            width 48px
            height 48px
            margin-right 10px
            bg-image2('msyq-question/answer-title-ico20230412')
            background-size 100% 100%
            background-repeat no-repeat

        .answer-inp
          margin-top 40px
          box-sizing border-box
          background rgba(248, 248, 250, 1)
          border 1px solid rgba(223, 223, 223, 1)
          border-radius 12px

        .answer-notice
          width 100%
          margin-top 46px
          font-size 30px

        .answer-btn
          display flex
          align-items center
          justify-content center
          width 100%
          height 88px
          margin-top 40px
          font-size 30px
          color rgba(255, 255, 255, 1)
          background rgba(213, 99, 20, 1)
          border-radius 12px

  .rubber-band
    animation rubberBand .8s linear 0.1s infinite
    -moz-animation rubberBand .8s linear 0.1s infinite
    -webkit-animation rubberBand .8s linear 0.1s infinite
    -o-animation rubberBand .8s linear 0.1s infinite

  @keyframes rubberBand
    from
      transform scale3d(1.25, 1.25, 1)
    50%
      transform scale3d(1, 1, 1)
    to
      transform scale3d(1.25, 1.25, 1)
</style>
